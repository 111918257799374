import React from 'common/react-vendor';
import {createPersistedReducer} from 'common/app/utilities/persistance';
import {usePromise} from 'common/app/utilities/data-fetching';
import {useDeferred} from 'common/app/utilities/deferred';
import {LoadingOverlay} from 'widgets/overlay';
import {Spinner} from 'widgets/spinner';
import {Table} from 'widgets/dataviews/table/Table';
import {
	SortState,
	setSortKey,
	SortDirection,
} from 'widgets/dataviews/column-sort';
import {PaginationState, Pagination} from 'widgets/dataviews/pagination';
import {
	formatByFundamentalType,
	FundamentalType,
} from 'app/utilities/FundamentalTypeUtility';
import {createColumnWidthsProvider} from 'components/datacloud/query/results/rebuild/resizableColumnsProvider';
import {
	DNBButton,
	DNBTypography,
	FilterAltOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {ExportEntityDialog} from 'common/components/Dialog/ExportEntityDialog';
import {IEntityType} from 'common/components/datacloud/journeystage/journey.helpers';
import {isBuyerJourneyEnabled} from 'common/stores/tenantConfig';
import NoticeService from 'common/components/notice/NoticeService';
import {
	AttributeGroupAttribute,
	getAttributeGroupAttributes,
} from '../../query';
import {ExportButton} from '../../../../../../atlas/app/segmentation/Components/SegmentTitleButtons';
import {
	DEFAULT_COLUMN_GROUP_ID,
	ColumnIds,
	createCustomColumnsProvider,
	ColumnProvider,
	ColumnSelect,
	useCustomColumns,
	Customizations,
} from './column-customization';
import {getSessionSegmentState} from './segment.helpers';
import styles from './index.module.css';

const {useState, useEffect, useMemo} = React;

export const isListSegment = (): boolean => {
	const {isCompanyList = false, isContactList = false} =
		getSessionSegmentState() ?? {};

	return isCompanyList || isContactList;
};

export const ACCOUNTS_PAGE_VIEW_KEY = 'myDataQueryResultsAccountsView';
export const ACCOUNTS_PAGE_COLUMNS_KEY = 'myDataQueryResultsAccountsColumns';
const ACCOUNTS_PAGE_DEFAULT_SELECTIONS: Record<string, ColumnIds> = {
	[DEFAULT_COLUMN_GROUP_ID]: [
		['Account.CompanyName'],
		[
			'Account.LDC_DUNS',
			'Account.LDC_Street',
			'Account.City',
			'Account.State',
			'Account.LDC_PrimaryIndustry',
			'CuratedAccount.Engagement',
		],
	],
};
export const CONTACTS_PAGE_VIEW_KEY = 'myDataQueryResultsContactsView';
export const CONTACTS_PAGE_COLUMNS_KEY = 'myDataQueryResultsContactsColumns';
const CONTACTS_PAGE_DEFAULT_SELECTIONS: Record<string, ColumnIds> = {
	[DEFAULT_COLUMN_GROUP_ID]: [
		['Account.CompanyName'],
		['Contact.ContactName', 'Contact.Email', 'CuratedAccount.Engagement'],
	],
};
const COLUMN_WIDTHS_KEY = 'queryResultsColumnWidths';

export interface TableQuery {
	attributeGroup: string;
	textSearch: string;
	pagination: PaginationState;
	sort: SortState;
	segment: undefined | string;
}

export const LAST_SELECTED_ATTRIBUTE_GROUP_CACHE_KEY =
	'lastSelectedAttributeGroup';
const initialQuery: TableQuery = {
	attributeGroup:
		localStorage.getItem(LAST_SELECTED_ATTRIBUTE_GROUP_CACHE_KEY) ||
		DEFAULT_COLUMN_GROUP_ID,
	textSearch: '',
	pagination: {page: 0, pageSize: 15},
	sort: null,
	segment: undefined,
};

const initialAccountsQuery: TableQuery = {
	...initialQuery,
	sort: ['CuratedAccount.Engagement', SortDirection.Desc],
};

export type TableAction =
	| {type: 'setAttributeGroup'; group: string}
	| {type: 'setSearch'; searchText: string}
	| {type: 'setPagination'; pagination: PaginationState}
	| {type: 'setSort'; sortBy: string}
	| {type: 'setSegment'; segment: string | undefined};

const queryReducer = (_query: TableQuery, action: TableAction): TableQuery => {
	const query = {..._query};

	// Any change to the view should reset what page we're on
	if (action.type !== 'setPagination')
		query.pagination = {...query.pagination, page: 0};

	// When switching attribute group sorting state should be reset
	if (action.type === 'setAttributeGroup') query.sort = null;

	switch (action.type) {
		case 'setSegment':
			return {
				...query,
				segment: action.segment,
			};
		case 'setAttributeGroup':
			return {
				...query,
				attributeGroup: action.group,
			};
		case 'setSearch':
			return {
				...query,
				textSearch: action.searchText,
			};
		case 'setPagination':
			return {
				...query,
				pagination: action.pagination,
			};
		case 'setSort':
			return {
				...query,
				sort: setSortKey(query.sort, action.sortBy),
			};
		default: {
			console.error('Unknown table action: ', action);
			return _query;
		}
	}
};

export type CustomColumnsParams = {groupID: string; group: ColumnIds};

const customColumnsQueryReducer = (
	_query: Customizations,
	params: CustomColumnsParams
): Customizations => {
	const query = {..._query};

	return {
		...query,
		[params.groupID]: params.group,
	};
};

export const useContactsCustomColumnsQuery = createPersistedReducer(
	CONTACTS_PAGE_COLUMNS_KEY,
	customColumnsQueryReducer,
	{}
);
export const useAccountsCustomColumnsQuery = createPersistedReducer(
	ACCOUNTS_PAGE_COLUMNS_KEY,
	customColumnsQueryReducer,
	{}
);

export interface QueryResult {
	entityCount: number;
	entities: common.Entity[];
}

// Shared between AccountsPage and ContactsPage
export interface ResultsPageProps {
	entityType: IEntityType;
	filter?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	runQuery: (
		q: TableQuery,
		lookups: common.QueryAttribute[]
	) => Promise<QueryResult>;
	onOpenAccount?: (queryParams: Record<string, string>) => void;
	segmentNames?: Record<string, string>;
	onChangeSegment: (segmentName: string) => void;
	segment: string;
}

const useAccountsTableQuery = createPersistedReducer(
	ACCOUNTS_PAGE_VIEW_KEY,
	queryReducer,
	initialAccountsQuery,
	sessionStorage
);
const useAccountsCustomizations = createCustomColumnsProvider(
	ACCOUNTS_PAGE_COLUMNS_KEY
);
const isAttributeAllowedOnAccountsPage = ({
	Entity,
}: AttributeGroupAttribute): boolean =>
	![
		'Contact',
		'ContactMarketingActivity',
		'ContactSalesActivity',
		'CuratedContact',
	].includes(Entity);

const useContactsTableQuery = createPersistedReducer(
	CONTACTS_PAGE_VIEW_KEY,
	queryReducer,
	initialQuery,
	sessionStorage
);
const useContactsCustomizations = createCustomColumnsProvider(
	CONTACTS_PAGE_COLUMNS_KEY
);

const useColumnWidths = createColumnWidthsProvider(COLUMN_WIDTHS_KEY);

// The AccountsPage & ContactsPage components do the same thing, but they're
// duplicated so that the componentTypes are !== and react unmounts hooks when
// switching between them

export function AccountsPage({
	entityType,
	filter,
	runQuery,
	onOpenAccount,
	segmentNames,
	onChangeSegment,
	segment,
}: ResultsPageProps): JSX.Element {
	const [query, dispatch] = useAccountsTableQuery();
	const [customizations, setCustomizations] = useAccountsCustomizations();
	const [columnWidths, setColumnWidths] = useColumnWidths();
	const [, customColumnsDispatch] = useAccountsCustomColumnsQuery();
	return (
		<ColumnProvider
			selectedGroup={query.attributeGroup}
			onSelectGroup={(group) => dispatch({type: 'setAttributeGroup', group})}
			customizations={customizations}
			onCustomize={setCustomizations}
			columnWidths={columnWidths}
			setColumnWidths={setColumnWidths}
			defaultSelections={ACCOUNTS_PAGE_DEFAULT_SELECTIONS}
			isAttributeAllowed={isAttributeAllowedOnAccountsPage}
			setAttributeGroup={customColumnsDispatch}>
			<ResultsView
				entityType={entityType}
				filter={filter}
				segmentNames={segmentNames}
				query={query}
				runQuery={runQuery}
				dispatch={dispatch}
				onChangeSegment={onChangeSegment}
				onOpenAccount={onOpenAccount}
				segment={segment}
				hideColumnSelect={getSessionSegmentState()?.isCompanyList}
			/>
		</ColumnProvider>
	);
}

export function ContactsPage({
	entityType,
	filter,
	runQuery,
	segmentNames,
	onChangeSegment,
	segment,
	onOpenAccount,
}: ResultsPageProps): JSX.Element {
	const [query, dispatch] = useContactsTableQuery();
	const [customizations, setCustomizations] = useContactsCustomizations();
	const [columnWidths, setColumnWidths] = useColumnWidths();
	const [, customColumnsDispatch] = useContactsCustomColumnsQuery();
	return (
		<ColumnProvider
			selectedGroup={query.attributeGroup}
			onSelectGroup={(group) => dispatch({type: 'setAttributeGroup', group})}
			customizations={customizations}
			onCustomize={setCustomizations}
			columnWidths={columnWidths}
			setColumnWidths={setColumnWidths}
			defaultSelections={CONTACTS_PAGE_DEFAULT_SELECTIONS}
			setAttributeGroup={customColumnsDispatch}>
			<ResultsView
				entityType={entityType}
				filter={filter}
				onChangeSegment={onChangeSegment}
				onOpenAccount={onOpenAccount}
				segmentNames={segmentNames}
				query={query}
				runQuery={runQuery}
				dispatch={dispatch}
				segment={segment}
				hideColumnSelect={getSessionSegmentState()?.isContactList}
			/>
		</ColumnProvider>
	);
}

export interface ResultsViewProps {
	entityType: IEntityType;
	filter?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	query: TableQuery;
	runQuery: (
		q: TableQuery,
		lookups: common.QueryAttribute[]
	) => Promise<QueryResult>;
	dispatch: React.Dispatch<TableAction>;
	onOpenAccount?: (queryParams: Record<string, string>) => void;
	segmentNames?: Record<string, string>;
	onChangeSegment: (segmentName: string) => void;
	segment: string;
	hideColumnSelect?: boolean;
}

function ResultsView({
	entityType,
	filter,
	query,
	runQuery,
	dispatch,
	onOpenAccount,
	segment,
	hideColumnSelect = false,
}: ResultsViewProps): JSX.Element {
	React.useEffect(() => {
		// on init, query.segment in sessionStorage may be out of sync with segment in URL path
		if (segment !== query.segment) {
			dispatch({type: 'setSegment', segment});
		}
		// override eslint to not depend on query changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [columns, lookups] = useCustomColumns(query, dispatch, onOpenAccount);
	const [attributeGroupAttributes, setAttributeGroupAttributes] =
		React.useState<Record<string, AttributeGroupAttribute> | undefined>(
			undefined
		);
	React.useEffect(() => {
		setAttributeGroupAttributes(undefined);
		void getAttributeGroupAttributes(query.attributeGroup).then(
			(attributeGroupAttributes) => {
				const keyedAttributeGroupAttributes = Object.fromEntries(
					attributeGroupAttributes.map((attributeGroupAttribute) => [
						attributeGroupAttribute.AttrName,
						attributeGroupAttribute,
					])
				);
				setAttributeGroupAttributes(keyedAttributeGroupAttributes);
			}
		);
	}, [query.attributeGroup]);

	const debounce = useDebounce(query, 300);
	const queryLookups = useMemo(
		() => lookups,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[lookups.length]
	);
	const queryInputs = useMemo<[TableQuery, common.QueryAttribute[]]>(
		() => [debounce, queryLookups],
		[debounce, queryLookups]
	);

	const queryResult = usePromise(runQuery, ...queryInputs);

	const formattedData = queryResult.data
		? ({
				...queryResult.data,
				entities: queryResult.data.entities?.map((entity) => {
					return Object.fromEntries(
						Object.entries(entity).map(([key, value]) => {
							const fundamentalTypeKey =
								(attributeGroupAttributes &&
									attributeGroupAttributes[key]?.FundamentalType) ||
								'alpha';
							const fundamentalType =
								FundamentalType[
									fundamentalTypeKey as keyof typeof FundamentalType
								];
							return [key, formatByFundamentalType(value, fundamentalType)];
						})
					);
				}),
		  } as QueryResult)
		: null;

	// We should show the loading overlay if we've been loading for over 1s (700ms here + 300ms from above)
	const showLoadingOverlay =
		useDeferred(queryResult.loading, 700) && queryResult.loading;

	const [isExportAccountDialogOpen, setExportAccountDialogOpen] =
		React.useState(false);
	const [isExportContactDialogOpen, setExportContactDialogOpen] =
		React.useState(false);

	const exportButtonClick = React.useCallback(() => {
		const noExport = queryResult.data?.entityCount === 0;
		if (noExport) {
			NoticeService.warning({
				message: `Cannot export segment: This segment contains no ${entityType}.`,
			});
			return;
		}
		entityType === 'accounts'
			? setExportAccountDialogOpen?.(true)
			: setExportContactDialogOpen?.(true);
	}, [entityType, queryResult.data?.entityCount]);

	const buttons = React.useMemo(
		() => [
			{
				...ExportButton(),
				isHidden: false,
				onClick: exportButtonClick,
			},
		],
		[exportButtonClick]
	);

	const onClickRow = (row: common.Entity<string>): void => {
		const {AccountId, ContactId} = row;
		onOpenAccount &&
			onOpenAccount({
				a360_accountId: AccountId?.toString() || '',
				...(!ContactId ? {} : {a360_contactId: ContactId as string}),
			});
	};
	return (
		<>
			{isExportAccountDialogOpen && (
				<ExportEntityDialog
					data={isExportAccountDialogOpen}
					setData={setExportAccountDialogOpen}
					entity='ACCOUNT'
					entityType={entityType}
				/>
			)}
			{isExportContactDialogOpen && (
				<ExportEntityDialog
					data={isExportContactDialogOpen}
					setData={setExportContactDialogOpen}
					entity='ACCOUNT_AND_CONTACT'
					entityType={entityType}
				/>
			)}
			<div className={styles.configBar}>
				<div className={styles.configBarLeft}>
					{filter && (
						<DNBButton
							size='compact'
							variant='secondary'
							startIcon={<FilterAltOutlinedIcon />}
							onClick={() => filter[1]((pre) => !pre)}>
							<DNBTypography
								variant='compact-medium'
								color={(theme) => theme.colors.ColorPrimaryBlueInteractive}>
								Filters
							</DNBTypography>
						</DNBButton>
					)}
					<input
						type='text'
						className={styles.searchInput}
						placeholder='Search by companies or attributes'
						value={query.textSearch}
						onChange={(e) =>
							dispatch({type: 'setSearch', searchText: e.target.value})
						}
					/>
				</div>
				<div className={styles.configBarLeft}>
					{!hideColumnSelect && <ColumnSelect />}
					{isBuyerJourneyEnabled() &&
						buttons
							.filter(({isHidden}) => !isHidden)
							.map(({isHidden, ...prop}) => (
								<DNBButton key={prop.title} {...prop}>
									{prop.title}
								</DNBButton>
							))}
				</div>
			</div>
			{queryResult.error && (
				<details>
					<summary>
						An unexpected error occurred when loading the data.{' '}
						<button type='button' onClick={queryResult.invalidate}>
							Retry
						</button>
					</summary>
					{queryResult.error.message}
				</details>
			)}
			{formattedData ? (
				<div
					className={`${
						formattedData.entities?.length > 0 ? styles.tableWrapper : ''
					}`}>
					<LoadingOverlay loading={showLoadingOverlay}>
						<Table
							rows={formattedData.entities}
							columns={columns}
							onClickRow={!isListSegment() ? onClickRow : undefined}
						/>
						{formattedData.entities.length === 0 && (
							<h5 style={{textAlign: 'center', marginTop: '.4em'}}>
								No results found
							</h5>
						)}
					</LoadingOverlay>
				</div>
			) : (
				<Spinner />
			)}
			{queryResult.data?.entityCount ? (
				<div className={styles.paginationWrapper}>
					<Pagination
						pagination={query.pagination}
						onChange={(pagination) =>
							dispatch({type: 'setPagination', pagination})
						}
						pageSizes={[15, 50, 100]}
						rowCount={queryResult.data.entityCount}
					/>
				</div>
			) : null}
		</>
	);
}

function useDebounce<Val>(val: Val, ms: number): Val {
	const [debounced, setDebounced] = useState(val);

	useEffect(() => {
		const id = setTimeout(() => setDebounced(val), ms);
		return () => clearTimeout(id);
	}, [val, ms]);

	return debounced;
}

export function getDeaultSelections(isAccount: boolean): ColumnIds {
	return isAccount
		? ACCOUNTS_PAGE_DEFAULT_SELECTIONS[DEFAULT_COLUMN_GROUP_ID]!
		: CONTACTS_PAGE_DEFAULT_SELECTIONS[DEFAULT_COLUMN_GROUP_ID]!;
}

import {
	DNBButton,
	DNBCheckbox,
	DNBRadio,
	DNBRadioGroup,
	DNBSelect,
	DNBSelectOption,
	DNBTypography,
} from '@dnb-uux-design-system/react';
import {GenericFetcher} from 'atlas/connectors/EIF/Fetcher';
import {ExportType} from 'common/components/datacloud/datacloud.types';
import {ICell} from 'atlas/JourneyStage/JourneyStageConfiguration/Component/EditableCell';
import React from 'common/react-vendor';
import {getQueryProperty} from 'common/stores/query';
import {QueryCounts} from 'common/stores/query/types';
import {
	FreeStyleDialogContent,
	FreeStyleStickyFooter,
	getFreeStyleDialog,
} from 'common/composite-uux-components/dialog-drawer/DialogDrawer';
import {
	exportSegment,
	getAttributeSetUrl,
	getCurrentAttributeGroup,
} from './ExportHelper';
import {showExportBanner} from '../datacloud/tabs/subheader/SubheaderUtility';

type IExportType = {
	value: ExportType;
	label: string;
	show: boolean;
};

const ExportTypeList = (
	hasAccount: boolean,
	hasContact: boolean
): IExportType[] => {
	return [
		{value: 'ACCOUNT', label: 'Enriched Accounts', show: hasAccount},
		{
			value: 'ACCOUNT_AND_CONTACT',
			label: 'Enriched Contacts with Account Attributes',
			show: hasAccount && hasContact,
		},
		{
			value: 'CONTACT',
			label: 'Enriched Contacts(No Account Attributes)',
			show: hasContact,
		},
	];
};

type IAttributeGroup = {
	name: string;
	displayName: string;
};

const StyledExportDialog = getFreeStyleDialog({
	minWidth: '768px',
	width: '768px',
	maxHeight: '420px',
});

type IExportDialog = ICell<boolean>;

/**
 * Export dialog.
 * @param prop @IIExportDialog
 */
const ExportDialog = ({
	data: open,
	setData: setOpen,
}: IExportDialog): React.ReactElement => {
	const {accounts, contacts} = getQueryProperty<QueryCounts>('counts');
	const [exportType, setExportType] = React.useState<ExportType>(
		'ACCOUNT_AND_CONTACT'
	);
	const [exportTimestamp, setExportTimeStamp] = React.useState(false);
	const [attributeGroup, setAttributeGroup] = React.useState(
		getCurrentAttributeGroup()
	);
	const [isExporting, setExporting] = React.useState(false);
	return (
		<StyledExportDialog open={open} onClose={() => setOpen(false)}>
			<DNBTypography variant='h6' ml={6} mt={6} mb={3}>
				Export
			</DNBTypography>
			<FreeStyleDialogContent>
				<DNBTypography>Select the records you want to export</DNBTypography>
				<DNBRadioGroup
					value={exportType}
					onChange={(_, value) => setExportType(value as ExportType)}>
					{ExportTypeList(!!accounts.value, !!contacts.value)
						.filter(({show}) => show)
						.map(({value, label}) => (
							<DNBRadio
								key={value}
								id={value}
								size='compact'
								disabled={isExporting}
								value={value}
								label={label}
							/>
						))}
				</DNBRadioGroup>
				<DNBTypography>Would you like to export timestamp?</DNBTypography>
				<DNBCheckbox
					checked={exportTimestamp}
					label='Export Timestamp'
					disabled={isExporting}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setExportTimeStamp(e.target.checked)
					}
				/>
				<DNBTypography>Select the attribute group</DNBTypography>
				<GenericFetcher<IAttributeGroup[]>
					apiConfig={{url: getAttributeSetUrl}}>
					{(data) => (
						<DNBSelect
							optionsMaxHeight='80%'
							value={attributeGroup}
							onChange={(_, value) => {
								if (!value) return;
								setAttributeGroup(value);
							}}>
							{data.map(({name, displayName}) => (
								<DNBSelectOption
									key={name}
									value={name}
									disabled={isExporting}
									label={displayName}>
									<DNBTypography variant='compact-body'>
										{displayName}
									</DNBTypography>
								</DNBSelectOption>
							))}
						</DNBSelect>
					)}
				</GenericFetcher>
			</FreeStyleDialogContent>
			<FreeStyleStickyFooter>
				<DNBButton
					variant='secondary'
					size='compact'
					onClick={() => setOpen(false)}>
					Cancel
				</DNBButton>
				<DNBButton
					variant='primary'
					size='compact'
					isLoading={isExporting}
					onClick={async () => {
						setExporting(true);
						const success = await exportSegment(
							exportType,
							exportTimestamp,
							attributeGroup
						);
						if (success) {
							showExportBanner();
						}
						setExporting(false);
						setOpen(false);
					}}>
					Export
				</DNBButton>
			</FreeStyleStickyFooter>
		</StyledExportDialog>
	);
};

export type {IAttributeGroup, IExportDialog};
export {ExportDialog};

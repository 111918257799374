enum TenantConfigActionType {
	SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS',
	SET_MODULES = 'SET_MODULES',
	SET_PRODUCTS = 'SET_PRODUCTS',
	SET_IS_FETCHING = 'SET_IS_FETCHING',
	SET_TENANT_CONFIG = 'SET_TENANT_CONFIG',
}

/**
 * @param ENABLE_BUYER_JOURNEY_STAGE segment dashboard and journey stage library.
 * @param ENABLE_BUYER_JOURNEY_ANALYTICS segment dashboard analytics cards.
 */
enum FeatureFlags {
	ACTIVATE_MODEL_PAGE = 'ActivateModelPage',
	ADD_USER = 'AddUsers',
	ADMIN_ALERTS_TAB = 'AdminAlertsTab',
	ADMIN_PAGE = 'AdminPage',
	ALLOW_PIVOT_FILE = 'AllowPivotFile',
	ALPHA_FEATURE = 'AlphaFeature',
	ENABLE_DSP_THIRD_PARTY = 'EnableDspThirdParty',
	ENABLE_DSP_BRAND_SAFETY_SEGMENTS = 'EnableDspBrandSafetySegments',
	CDP_PERSONALIZATION = 'CDP_Personalization',
	CHANGE_MODEL_NAME = 'ChangeModelNames',
	CHANGE_USER_ACCESS = 'ChangeUserAccess',
	DAILY_INGESTION_AND_LAUNCH = 'DailyIngestionAndLaunch',
	DELETE_MODEL = 'DeleteModels',
	DELETE_USER = 'DeleteUsers',
	DEPLOYMENT_WIZARD_PAGE = 'DeploymentWizardPage',
	EDIT_REFINE_CLONE = 'Edit_Refine_Clone',
	ENABLE_ACTIVATION_ACCOUNT_VIEW_REPORTING = 'EnableActivationAccountViewReporting',
	ENABLE_ASYNC_FOR_CAMPAIGN_RELATED_PAGE = 'EnableAsyncForCampaignRelatedPage',
	ENABLE_BACKEND_PAGINATION_FOR_CAMPAIGN_RELATED_PAGE = 'EnableBackendPaginationForCampaignRelatedPage',
	ENABLE_CALCULATE_KNOWN_CONTACT = 'EnableCalculateKnownContact',
	ENABLE_CROSS_SELL_MODELING = 'EnableCrossSellModeling',
	ENABLE_DAILY_MAP_IMPORT = 'EnableDailyMAPImport',
	ENABLE_ENTITY_MATCH = 'EnableEntityMatch',
	ENABLE_FILE_IMPORT = 'EnableFileImport',
	ENABLE_HORIZONTAL_NAVIGATION = 'EnableHorizontalNavigation',
	ENABLE_INTERNAL_ENRICHMENT_ATTRIBUTES = 'EnableInternalEnrichmentAttributes',
	ENABLE_MARKETPLACE_SEGMENTS = 'EnableMarketplaceSegments',
	ENABLE_PERFORMANCE_REPORT = 'EnablePerformanceReport',
	ENABLE_PRODUCT_BUNDLE_IMPORT = 'EnableProductBundleImport',
	ENABLE_PRODUCT_HIERARCHY_IMPORT = 'EnableProductHierarchyImport',
	ENABLE_PRODUCT_PURCHASE_IMPORT = 'EnableProductPurchaseImport',
	ENABLE_SEGMENTATION_V2 = 'EnableSegmentationV2',
	ENABLE_BUYER_JOURNEY_STAGE = 'EnableBuyerJourneyStage',
	ENABLE_BUYER_JOURNEY_ANALYTICS = 'EnableBuyerJourneyAnalytics',
	ENABLE_BUYER_JOURNEY_SUBSTAGE = 'EnableBuyerJourneySubstage',
	ENABLE_TAM_REPORTING = 'EnableTAMReporting',
	ENABLE_TIME_SERIES_SEGMENT = 'EnableTimeSeriesSegment',
	ENTERPRISE_INTEGRATION = 'EnterpriseIntegration',
	JOBS_PAGE = 'JobsPage',
	LATTICE_INSIGHTS = 'LatticeInsights',
	LEAD_ENRICHMENT_PAGE = 'LeadEnrichmentPage',
	LIST_SEGMENT_MATERIALIZATION = 'listSegmentMaterialization',
	MARKETO_SETTINGS_PAGE = 'MarketoSettingsPage',
	MIGRATION_TENANT = 'MigrationTenant',
	MODEL_HISTORY_PAGE = 'ModelCreationHistoryPage',
	NEW_ADMIN_PAGE = 'NewAdminPage',
	PLAYBOOK_MODULE = 'PlaybookModule',
	REDIRECT_TO_DEPLOYMENT_WIZARD_PAGE = 'RedirectToDeploymentWizardPage',
	REVIEW_MODEL = 'ReviewModel',
	SCORE_EXTERNAL_FILE = 'ScoreExternalFile',
	SETUP_PAGE = 'SetupPage',
	SSVI = 'SSVI',
	SYSTEM_SETUP_PAGE = 'SystemSetupPage',
	UPLOAD_JSON = 'UploadSummaryJson',
	USER_MGMT_PAGE = 'UserManagementPage',
	VDB_MIGRATION = 'VDBMigration',
	VIEW_REFINE_CLONE = 'View_Refine_Clone',
	VIEW_REMODEL = 'View_Remodel',
	VIEW_SAMPLE_LEADS = 'View_Sample_Leads',
	HideSalesEventObjects = 'HideSalesEventObjects',
	ENABLE_SUBSCRIPTION_PAGE_ANALYTICS = 'EnableSubscriptionPageAnalytics',
	ENABLE_WEB_INSIGHTS_NEW_UI = 'EnableWebInsightsNewUI',
	ENABLE_SALESFORCE_MARKETING_CLOUD_INTEGRATION = 'EnableSalesforceMarketingCloudIntegration',
	ENABLE_XANDR_CURATE_INTEGRATION = 'EnableXandrCurateIntegration',
	ENABLE_LIST_SEGMENT_IMPROVEMENT = 'EnableListSegmentImprovement',
	ENABLE_WEB_INSIGHTS_CREATE_SEGMENT = 'EnableWebInsightsCreateSegment',
	ENABLE_QUERY_BUILDER_REDESIGN = 'EnableQueryBuilderRedesign',
	ENABLE_G2_CONNECTION = 'EnableG2Connection',
	ENABLE_MODELING_WITH_TRAINING_DATA_SEGMENT = 'EnableModelingWithTrainingDataSegment',
	ENABLE_AC360_REDESIGN = 'EnableAc360Redesign',
	DISABLE_BUYER_JOURNEY_CAMPAIGN_LAUNCH = 'DisableBuyerJourneyCampaignLaunch',
	ENABLE_ACTIVATION_MSFT_DYNAMICS_INTEGRATION = 'EnableActivationMSFTDynamicsIntegration',
	ENABLE_MS_DYNAMICS365_INTEGRATION = 'EnableMSDynamics365Integration',
	ENABLE_ACTIVATION_HUB_SPOT_INTEGRATION = 'EnableActivationHubSpotIntegration',
	ENABLE_ACTIVATION_SALES_LOFT_INTEGRATION = 'EnableActivationSalesLoftIntegration',
	ENABLE_SFMC_TO_S3_TRAY_INTEGRATION = 'EnableSfmcToS3TrayIntegration',
	ENABLE_SFMC_TO_S3_STITCH_INTEGRATION = 'EnableSfmcToS3StitchIntegration',
	ENABLE_SAVING_ATTRIBUTES_IN_ATTRIBUTE_GROUP = 'EnableSavingAttributesInAttributeGroup',
	ENABLE_AI_TALKING_POINTS = 'EnableAITalkingPoints',
	ENABLE_IMPORT_HUB_SPOT = 'EnableHubSpotImportConnectionTray',
	ENABLE_QUERY_BUILDER_REFACTOR = 'EnableQueryBuilderRefactor',
}

enum Modules {
	SSVI = 'SSVI',
	TALKING_POINT = 'Talking Point',
	EXPLORE = 'Explore',
	HAND_HOLD_PA = 'Hand-hold PA',
	CDL = 'CDL',
	LIST_SEGMENT = 'ListSegment',
}

export {FeatureFlags, Modules, TenantConfigActionType};

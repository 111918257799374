import {SystemType} from 'atlas/connectors/EIF/Data/SystemType';
import {
	SendAction,
	SendType,
} from 'atlas/playbook/content/liveramplaunch/types';

// I am just following the same pattern that was already established by the old mapping...
export const getFieldMappingKey = (): number =>
	Math.floor(Math.random() * Date.now());

// #region MandatoryDestinationFields
export const salesforceMarketingCloudMandatoryDestinationFields = [];

export const MandatoryDestinationFieldsMap = new Map<
	string,
	(string | string[])[]
>([
	[
		SystemType.Salesforce_Marketing_Cloud,
		salesforceMarketingCloudMandatoryDestinationFields,
	],
	[
		`${SystemType.Salesforce}_${SendType.ACCOUNTS}_${SendAction.CREATE}`,
		['Name', 'Phone'],
	],
	[
		`${SystemType.Salesforce}_${SendType.CONTACTS}_${SendAction.CREATE}`,
		['FirstName', 'LastName', 'Phone', 'Email'],
	],
	[
		`${SystemType.Salesforce}_${SendType.LEADS}_${SendAction.CREATE}`,
		['FirstName', 'LastName', 'Phone', 'Email', 'Company'],
	],
	['ModelUpload', ['Success Criteria']],
	[
		`${SystemType.Hub_Spot}_${SendType.ACCOUNTS}_${SendAction.CREATE}`,
		[['name', 'domain']],
	],
	[
		`${SystemType.Hub_Spot}_${SendType.CONTACTS}_${SendAction.CREATE}`,
		['email'],
	],
	[
		`${SystemType.Microsoft_Dynamics365}_${SendType.ACCOUNTS}_${SendAction.CREATE}`,
		['name'],
	],
	[
		`${SystemType.Microsoft_Dynamics365}_${SendType.CONTACTS}_${SendAction.CREATE}`,
		['firstname', 'lastname'],
	],
	[
		`${SystemType.Microsoft_Dynamics365}_${SendType.LEADS}_${SendAction.CREATE}`,
		['topic', 'lastname'],
	],
]);
// #endregion MandatoryDestinationFields

// #region MandatorySourceFieldsMap
export const eloquaMandatorySourceFields = ['Email'];

// [TODO]: Refactor field mappings since it's too complicated now.
export const MandatorySourceFieldsMap = new Map<SystemType, string[]>([
	[SystemType.Eloqua, eloquaMandatorySourceFields],
]);
// #endregion MandatorySourceFieldsMap

// [TODO]: Refactor field mappings since it's too complicated now.
export const systemWithRequiredDataExtension = [
	SystemType.Salesforce_Marketing_Cloud,
];

// [TODO]: Refactor field mappings since it's too complicated now.
export const systemsWithLegacyCustomObject = [
	SystemType.Eloqua,
	SystemType.Salesforce,
	SystemType.Hub_Spot,
	SystemType.Microsoft_Dynamics365,
];

// [TODO]: REMOVE IT since we are all using new mapping L&F.
export const systemsWithNewMapping: SystemType[] = [
	SystemType.Salesforce_Marketing_Cloud,
	SystemType.Eloqua,
	SystemType.Salesforce,
	SystemType.Microsoft_Dynamics365,
];

// [TODO]: Refactor field mappings since it's too complicated now.
export const systemsWithAudienceOperation: SystemType[] = [
	SystemType.Salesforce,
	SystemType.Hub_Spot,
	SystemType.Microsoft_Dynamics365,
];

// [TODO]: Refactor field mappings since it's too complicated now.
export const systemsWithSolutionInstanceIdAPI: SystemType[] = [
	SystemType.Hub_Spot,
];
